import * as React from 'react';

import { clsx } from 'clsx';

import Icon, { iconDefinitions } from '../../icon';

const variants = {
  primary:
    'bg-primary-medium text-white hover:bg-primary-dark active:bg-primary-darker disabled:bg-neutral-black disabled:text-neutral-dark disabled:cursor-not-allowed',
  secondary:
    'border-2 border-primary-medium box-border text-white hover:bg-primary-blacker active:bg-primary-black disabled:text-neutral-darker disabled:border-neutral-blacker disabled:cursor-not-allowed',
  tertiary:
    'bg-primary-darker text-white hover:bg-primary-black active:bg-primary-blacker disabled:bg-neutral-black disabled:text-neutral-dark disabled:cursor-not-allowed',
  quaternary:
    'border-2 border-primary-darker box-border text-white hover:bg-primary-blacker active:bg-primary-black disabled:border-neutral-blacker disabled:text-neutral-darker disabled:cursor-not-allowed',
  quinary:
    'bg-white text-primary-blacker-bis hover:bg-neutral-whiter active:bg-neutral-white disabled:bg-neutral-black disabled:text-neutral-dark disabled:cursor-not-allowed',
  senary:
    'border-2 border-white box-border text-white hover:bg-primary-blacker active:bg-primary-black disabled:border-neutral-blacker disabled:text-neutral-darker disabled:cursor-not-allowed',
};

const sizes = {
  sm: 'py-[10px] px-[16px] max-w-fit h-10 text-sm gap-1 rounded-xl',
  md: 'py-[12px] px-[18px] max-w-fit h-12 text-md gap-2 rounded-[14px]',
  lg: 'py-[16px] px-[20px] max-w-fit h-14 text-lg gap-3 rounded-2xl',
  smhalf: 'py-[10px] px-[16px] text-sm w-1/2 h-10 rounded-xl',
  mdhalf: 'py-[12px] px-[18px] text-md w-1/2 h-12 rounded-[14px]',
  lghalf: 'py-[16px] px-[20px] text-lg h-14 w-1/2 rounded-2xl',
  smfull: 'py-[10px] px-[16px] text-sm w-full h-10 rounded-xl',
  mdfull: 'py-[12px] px-[18px] text-md w-full h-12 rounded-[14px]',
  lgfull: 'py-[16px] px-[20px] text-lg h-14 w-full rounded-2xl',
};

const iconsChoice = {
  plus: 'plus',
  doubleChevronRight: 'doubleChevronRight',
  discord: 'discord',
  facebook: 'facebook',
  github: 'github',
  instagram: 'instagram',
  linkedin: 'linkedin',
  twitter: 'twitter',
  youtube: 'youtube',
};

const widths: Record<keyof typeof sizes, number> = {
  sm: 15,
  md: 20,
  lg: 25,
  smhalf: 15,
  mdhalf: 20,
  lghalf: 25,
  smfull: 15,
  mdfull: 20,
  lgfull: 25,
};

type IconProps =
  | { startIcon: boolean; endIcon?: never }
  | { endIcon: boolean; startIcon?: never }
  | { endIcon?: undefined; startIcon?: undefined }
  | { startIcon: boolean; endIcon: boolean };

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: keyof typeof variants;
  size?: keyof typeof sizes;
  iconChoice?: keyof typeof iconsChoice;
  uncolouredIcon?: boolean;
  disabled?: boolean;
  focus?: boolean;
} & IconProps;

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      type = 'button',
      className = '',
      variant = 'primary',
      iconChoice,
      size = 'md',
      disabled = false,
      focus = false,
      uncolouredIcon = false,
      startIcon,
      endIcon,
      ...props
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        type={type}
        className={clsx(
          'flex items-center justify-between whitespace-nowrap',
          variants[variant],
          sizes[size],
          focus &&
            'ring-primary-pure focus:border-transparent focus:shadow-focus focus:ring-2',
          className
        )}
        disabled={disabled}
        {...props}
      >
        {iconChoice && startIcon && (
          <Icon
            iconDefinition={iconDefinitions[iconsChoice[iconChoice]]}
            uncoloured={uncolouredIcon}
            className={clsx(uncolouredIcon && 'stroke-current')}
            width={widths[size]}
          />
        )}

        <p className="flex-grow">{props.children}</p>

        {iconChoice && endIcon && (
          <Icon
            iconDefinition={iconDefinitions[iconsChoice[iconChoice]]}
            uncoloured={uncolouredIcon}
            className={clsx(uncolouredIcon && 'stroke-current')}
            width={widths[size]}
          />
        )}
      </button>
    );
  }
);

Button.displayName = 'Button';

export default Button;
