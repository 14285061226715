import { clsx } from 'clsx';

const trustUs = [
  'https://static.yumon.world/assets/partners/origins.png',
  'https://static.yumon.world/assets/partners/bga.png',
  'https://static.yumon.world/assets/partners/ubisoft.png',
  'https://static.yumon.world/assets/partners/stationf.png',
  'https://static.yumon.world/assets/partners/future40.png',
  'https://static.yumon.world/assets/partners/meta.png',
  'https://static.yumon.world/assets/partners/hec.png',
  'https://static.yumon.world/assets/partners/loreal.png',
  'https://static.yumon.world/assets/partners/bpi.png',
  'https://static.yumon.world/assets/partners/immutablex.png',
];

const poweredBy = {
  logo: 'https://static.yumon.world/assets/partners/starkware.png',
  width: 160,
  height: 30,
};

export default function PartnersSection() {
  return (
    <>
      <section className="mx-auto my-20 hidden max-h-min max-w-6.5xl flex-col items-center justify-center gap-20 px-6 xs:flex maxWidth:px-0">
        <h2 className="mx-auto mt-20 hidden w-full text-center text-5xl xs:block">
          <span
            className="block font-black italic text-primary-blacker-bis"
            style={{
              textShadow:
                'rgb(255, 255, 255) 6px 0px 0px, rgb(255, 255, 255) 5.91686px 0.995377px 0px, rgb(255, 255, 255) 5.66974px 1.96317px 0px, rgb(255, 255, 255) 5.2655px 2.87655px 0px, rgb(255, 255, 255) 4.71532px 3.71022px 0px, rgb(255, 255, 255) 4.03447px 4.44106px 0px, rgb(255, 255, 255) 3.24181px 5.04883px 0px, rgb(255, 255, 255) 2.35931px 5.51667px 0px, rgb(255, 255, 255) 1.41143px 5.83163px 0px, rgb(255, 255, 255) 0.424423px 5.98497px 0px, rgb(255, 255, 255) -0.574341px 5.97245px 0px, rgb(255, 255, 255) -1.55719px 5.79441px 0px, rgb(255, 255, 255) -2.49688px 5.45578px 0px, rgb(255, 255, 255) -3.36738px 4.96596px 0px, rgb(255, 255, 255) -4.14455px 4.33852px 0px, rgb(255, 255, 255) -4.80686px 3.59083px 0px, rgb(255, 255, 255) -5.33596px 2.74364px 0px, rgb(255, 255, 255) -5.71718px 1.8204px 0px, rgb(255, 255, 255) -5.93995px 0.84672px 0px, rgb(255, 255, 255) -5.99811px -0.150428px 0px, rgb(255, 255, 255) -5.89004px -1.14341px 0px, rgb(255, 255, 255) -5.61874px -2.1047px 0px, rgb(255, 255, 255) -5.19172px -3.00766px 0px, rgb(255, 255, 255) -4.62082px -3.82727px 0px, rgb(255, 255, 255) -3.92186px -4.54081px 0px, rgb(255, 255, 255) -3.11421px -5.12852px 0px, rgb(255, 255, 255) -2.22026px -5.57409px 0px, rgb(255, 255, 255) -1.26477px -5.86518px 0px, rgb(255, 255, 255) -0.274238px -5.99373px 0px, rgb(255, 255, 255) 0.723898px -5.95617px 0px, rgb(255, 255, 255) 1.70197px -5.75355px 0px, rgb(255, 255, 255) 2.63288px -5.39147px 0px, rgb(255, 255, 255) 3.49082px -4.87998px 0px, rgb(255, 255, 255) 4.25202px -4.23324px 0px, rgb(255, 255, 255) 4.89538px -3.46919px 0px, rgb(255, 255, 255) 5.40307px -2.60899px 0px, rgb(255, 255, 255) 5.76102px -1.67649px 0px, rgb(255, 255, 255) 5.95932px -0.697531px 0px',
            }}
          >
            Partners
          </span>
        </h2>
        <div className="flex flex-wrap items-center justify-center gap-[88px]">
          {trustUs.map((imgTrust, index) => (
            <img
              key={`${imgTrust} - trust us`}
              src={imgTrust}
              width={200}
              height="auto"
              alt="trust us"
              className={clsx(
                'h-[50px] object-contain object-center 1.5md:h-[70px]',
                (index === 0 || index === 2) && 'h-[90px] 1.5md:h-[110px]'
              )}
            />
          ))}
        </div>
        <div className="flex flex-wrap items-center justify-center gap-[88px] xs:mt-12">
          <div className="flex flex-col items-center justify-center gap-1 xxs:flex-row xxs:gap-3">
            <h3 className="pt-0.5 text-sm text-neutral-light 1.5md:text-xl">
              <span className="whitespace-nowrap">POWERED BY</span>
            </h3>
            <img
              src={poweredBy.logo}
              width={poweredBy.width}
              height={poweredBy.height}
              alt="powered by Starkware"
              className={'h-[30px] object-contain object-center 1.5md:h-[40px]'}
            />
          </div>
        </div>
      </section>
      <section className="mx-auto flex max-h-min max-w-6.5xl flex-col items-center justify-center gap-20 px-6 xs:hidden maxWidth:px-0">
        <h2 className="mx-auto block w-full text-center text-[32px] xs:hidden">
          <span
            className="block font-black italic text-primary-blacker-bis"
            style={{
              textShadow:
                'rgb(255, 255, 255) 6px 0px 0px, rgb(255, 255, 255) 5.91686px 0.995377px 0px, rgb(255, 255, 255) 5.66974px 1.96317px 0px, rgb(255, 255, 255) 5.2655px 2.87655px 0px, rgb(255, 255, 255) 4.71532px 3.71022px 0px, rgb(255, 255, 255) 4.03447px 4.44106px 0px, rgb(255, 255, 255) 3.24181px 5.04883px 0px, rgb(255, 255, 255) 2.35931px 5.51667px 0px, rgb(255, 255, 255) 1.41143px 5.83163px 0px, rgb(255, 255, 255) 0.424423px 5.98497px 0px, rgb(255, 255, 255) -0.574341px 5.97245px 0px, rgb(255, 255, 255) -1.55719px 5.79441px 0px, rgb(255, 255, 255) -2.49688px 5.45578px 0px, rgb(255, 255, 255) -3.36738px 4.96596px 0px, rgb(255, 255, 255) -4.14455px 4.33852px 0px, rgb(255, 255, 255) -4.80686px 3.59083px 0px, rgb(255, 255, 255) -5.33596px 2.74364px 0px, rgb(255, 255, 255) -5.71718px 1.8204px 0px, rgb(255, 255, 255) -5.93995px 0.84672px 0px, rgb(255, 255, 255) -5.99811px -0.150428px 0px, rgb(255, 255, 255) -5.89004px -1.14341px 0px, rgb(255, 255, 255) -5.61874px -2.1047px 0px, rgb(255, 255, 255) -5.19172px -3.00766px 0px, rgb(255, 255, 255) -4.62082px -3.82727px 0px, rgb(255, 255, 255) -3.92186px -4.54081px 0px, rgb(255, 255, 255) -3.11421px -5.12852px 0px, rgb(255, 255, 255) -2.22026px -5.57409px 0px, rgb(255, 255, 255) -1.26477px -5.86518px 0px, rgb(255, 255, 255) -0.274238px -5.99373px 0px, rgb(255, 255, 255) 0.723898px -5.95617px 0px, rgb(255, 255, 255) 1.70197px -5.75355px 0px, rgb(255, 255, 255) 2.63288px -5.39147px 0px, rgb(255, 255, 255) 3.49082px -4.87998px 0px, rgb(255, 255, 255) 4.25202px -4.23324px 0px, rgb(255, 255, 255) 4.89538px -3.46919px 0px, rgb(255, 255, 255) 5.40307px -2.60899px 0px, rgb(255, 255, 255) 5.76102px -1.67649px 0px, rgb(255, 255, 255) 5.95932px -0.697531px 0px',
            }}
          >
            Partners
          </span>
        </h2>
        <div className="flex flex-wrap items-center justify-center gap-[88px]">
          {trustUs.map((imgTrust, index) => (
            <img
              key={`${index} - trust us`}
              src={imgTrust}
              width={200}
              height="auto"
              alt="trust us"
              className={clsx(
                'h-[50px] object-contain object-center 1.5md:h-[70px]',
                (index === 0 || index === 2) && 'h-[90px] 1.5md:h-[110px]'
              )}
            />
          ))}
        </div>
        <div className="flex flex-wrap items-center justify-center gap-[88px] xs:mt-12">
          <div className="flex flex-col items-center justify-center gap-1 xxs:flex-row xxs:gap-3">
            <h3 className="pt-0.5 text-sm text-neutral-light 1.5md:text-xl">
              <span className="whitespace-nowrap">POWERED BY</span>
            </h3>
            <img
              src={poweredBy.logo}
              width={poweredBy.width}
              height={poweredBy.height}
              alt="powered by Starkware"
              className="h-[30px] object-contain object-center 1.5md:h-[40px]"
            />
          </div>
        </div>
      </section>
    </>
  );
}
