import { useEffect, useState } from 'react';

import { Button } from '../../../components/call-to-actions';

type IntroductionSectionDesktopProps = {
  backgroundImage: string;
};

export default function IntroductionSectionDesktop({
  backgroundImage,
}: IntroductionSectionDesktopProps) {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const onMouseMove = (e: MouseEvent) => {
      setPosition({
        x: (window.innerWidth - e.pageX * 1.4) / 90,
        y: (window.innerHeight - e.pageY * 1.4) / 90,
      });
    };
    document.addEventListener('mousemove', onMouseMove);
    return () => document.removeEventListener('mousemove', onMouseMove);
  }, []);

  return (
    <>
      <section
        className="relative h-fit max-h-[120vh] overflow-hidden bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(6, 0, 22, 0) 60%, rgba(6, 0, 22, 1) 100%), linear-gradient(to top, rgba(6, 0, 22, 0) 70%, rgba(6, 0, 22, 1) 100%), url(${backgroundImage})`,
        }}
      >
        <div className="mx-auto flex h-fit min-h-[900px] max-w-6.5xl flex-col justify-between px-6 maxWidth:px-0">
          <div className="max-h-fit">
            <div className="grid h-1/2 grid-cols-1 1.5md:grid-cols-4">
              <div className="relative mx-auto mt-52 flex flex-col justify-center gap-6 1.5md:col-span-3">
                <h1
                  style={{ fontWeight: 900 }}
                  className="z-10 mx-auto w-full text-[64px] text-white"
                >
                  <div
                    className="transition-transform duration-1000 ease-out"
                    style={{
                      transform: `translateX(${position.x / 4}px) translateY(${
                        position.y / 4
                      }px)`,
                      WebkitTransform: `translateX(${
                        position.x / 4
                      }px) translateY(${position.y / 4}px)`,
                    }}
                  >
                    <span className="word-space-widest yumon-text-shadow-8 block text-[64px] font-black italic text-primary-blacker-bis lg:whitespace-nowrap">
                      Play & Own Games
                    </span>
                  </div>
                  <span className="block w-3/4 text-[32px] leading-[1em] xs:text-[52px]">
                    Elevate mass entertainment playgrounds
                  </span>
                </h1>

                <p className="z-10 w-1/2 text-base text-white xs:text-[22px]">
                  Yumon enables developers, players and creators to shape the
                  future of play with cutting edge technologies and cross-game
                  digital toys.
                </p>

                <a
                  href="https://app.yumon.world/"
                  target="_blank"
                  rel="noreferrer"
                  className="z-10 w-fit"
                >
                  <Button
                    startIcon
                    iconChoice="doubleChevronRight"
                    variant="tertiary"
                    size="lg"
                    className="text-lg font-bold"
                  >
                    Play Now
                  </Button>
                </a>
              </div>
            </div>
            <div
              className="absolute hidden bg-auto bg-no-repeat transition-transform duration-1000 ease-out md:top-10 1.5md:block lg:top-[200px] lg:right-0 2xl:right-[160px]"
              style={{
                transform: `translateX(${position.x * 4}px) translateY(${
                  position.y * 4
                }px)`,
                WebkitTransform: `translateX(${position.x * 4}px) translateY(${
                  position.y * 4
                }px)`,
              }}
            >
              <img
                src="https://static.yumon.world/studio/textures/landingpage/layer3.png"
                alt="Homepage Card layer 2"
                width={750}
              />
            </div>
            <div
              className="absolute hidden bg-auto bg-no-repeat transition-transform duration-1000 ease-out md:top-10 1.5md:block lg:top-[-20px] lg:right-[-300px] 2xl:right-[-220px]"
              style={{
                transform: `translateX(${position.x * 2.6}px) translateY(${
                  position.y * 2.6
                }px)`,
                WebkitTransform: `translateX(${
                  position.x * 2.6
                }px) translateY(${position.y * 2.6}px)`,
              }}
            >
              <img
                src="https://static.yumon.world/studio/textures/landingpage/layer2.png"
                alt="Homepage Card layer 2"
                width={750}
              />
            </div>
            <div
              className="absolute hidden bg-no-repeat transition-transform duration-1000 ease-out md:top-10 1.5md:block lg:top-[200px] lg:right-[-300px] 2xl:right-[-200px]"
              style={{
                transform: `translateX(${position.x * 2}px) translateY(${
                  position.y * 2
                }px)`,
                WebkitTransform: `translateX(${position.x * 2}px) translateY(${
                  position.y * 2
                }px)`,
              }}
            >
              <img
                src="https://static.yumon.world/studio/textures/landingpage/layer1.png"
                alt="Homepage Card layer 5"
                width={750}
              />
            </div>
          </div>
        </div>
      </section>

      <div
        className="relative -mt-[900px] h-[900px] w-full"
        style={{
          background:
            'linear-gradient(to bottom, rgba(6, 0, 22, 0) 60%, rgba(6, 0, 22, 1) 100%), linear-gradient(to top, rgba(6, 0, 22, 0) 70%, rgba(6, 0, 22, 1) 100%)',
        }}
      ></div>
    </>
  );
}
