import { Button } from '../../call-to-actions';
import { Logo } from '../../logo';

export default function HeaderDesktop() {
  return (
    <nav
      className="mx-auto hidden h-full w-full max-w-6.5xl px-6 md:block maxWidth:px-0"
      aria-label="Top"
    >
      <div className="flex h-full w-full items-center justify-between">
        <div className="flex items-center gap-16">
          <a href="/">
            <Logo size="lg" variant="yumonWhiteOutline" />
          </a>
          <div className="flex gap-16 text-sm text-white">
            <a href="/#games" className="flex items-center font-bold">
              Games
            </a>
          </div>
          <div className="flex gap-16 text-sm text-white">
            <a href="/#technology" className="flex items-center font-bold">
              Technology
            </a>
          </div>
          <div className="flex gap-16 text-sm text-white">
            <a href="/#team" className="flex items-center font-bold">
              Team
            </a>
          </div>
        </div>
        <div className="flex gap-16">
          <div className="flex items-center gap-8">
            <a
              href="https://app.yumon.world/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button variant="primary" size="sm" className="font-bold">
                Get In
              </Button>
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
}
