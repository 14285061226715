import * as React from 'react';

import { clsx } from 'clsx';

import Icon, { iconDefinitions } from '../../icon';

const socialNetworks = {
  discord: '',
  instagram: '',
  twitter: '',
  facebook: '',
  twitch: '',
  google: '',
  metamask: '',
  immutablex: '',
  linkedin: '',
  youtube: '',
};

const sizes = {
  sm: 'p-0.5',
  md: 'p-0.5',
  lg: 'p-0.5',
  xl: 'p-0.5',
};

const widths: Record<keyof typeof sizes, number> = {
  sm: 20,
  md: 25,
  lg: 30,
  xl: 40,
};

type SocialLinkProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  socialNetwork?: keyof typeof socialNetworks;
  size?: keyof typeof sizes;
  disabled?: boolean;
  focus?: boolean;
  uncolouredLink: boolean;
  whiteIcon?: boolean;
};

const SocialLink = React.forwardRef<HTMLButtonElement, SocialLinkProps>(
  (
    {
      socialNetwork = 'google',
      size = 'md',
      disabled = false,
      focus = false,
      uncolouredLink = true,
      whiteIcon,
      ...props
    },
    ref
  ) => {
    const colorDisplay = () => {
      if (disabled) {
        return 'fill-current';
      } else if (whiteIcon && uncolouredLink) {
        return 'fill-white focus:fill-neutral-lighter hover:fill-neutral-whiter active:fill-neutral-white';
      } else if (uncolouredLink) {
        return 'fill-neutral-pure focus:fill-neutral-lighter hover:fill-neutral-lighter active:fill-neutral-whiter';
      } else {
        return undefined;
      }
    };
    return (
      <button
        ref={ref}
        type="button"
        title={socialNetwork}
        className={clsx(
          'flex items-center justify-center rounded-lg font-semibold disabled:text-neutral-darker',
          socialNetworks[socialNetwork],
          sizes[size],
          focus && 'ring-primary-pure focus:shadow-focus focus:ring-2'
        )}
        disabled={disabled}
        {...props}
      >
        {
          <Icon
            iconDefinition={iconDefinitions[socialNetwork]}
            className={colorDisplay()}
            disabled={disabled}
            width={widths[size]}
            uncoloured={uncolouredLink}
          />
        }
      </button>
    );
  }
);

SocialLink.displayName = 'SocialLink';

export default SocialLink;
