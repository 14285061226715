import { ReactNode } from 'react';

import { clsx } from 'clsx';
import { BsShopWindow, BsTrophy } from 'react-icons/bs';
import { IoFingerPrint, IoKeyOutline } from 'react-icons/io5';

import { useGetCurrentScreenSize } from '../../hooks/useGetScreenSize';

type Feature = {
  icon: ReactNode;
  title: string;
  description: string;
};

const FEATURES: Feature[] = [
  {
    icon: <IoFingerPrint color="#8840FF" size={50} />,
    title: 'Seamless Authentication',
    description:
      'All new players are equipped with a wallet through a simple social login registration. This wallet is at the heart of all our games.',
  },
  {
    icon: <BsShopWindow color="#8840FF" size={50} />,
    title: 'Cross-game shop & persistent IAP',
    description:
      'Avatars and game items are shared across all games on all OS. You purchases become persistent and liquid. Players can freely trade them on the marketplace, without fees.',
  },
  {
    icon: <IoKeyOutline color="#8840FF" size={50} />,
    title: 'Ownership guaranteed',
    description:
      'Buy avatars, lives and other items in the shop, they will be for you forever in all present and future games.',
  },
  {
    icon: <BsTrophy color="#8840FF" size={50} />,
    title: 'Turn the competition on!',
    description:
      'Mode your game and let Yumon players compete against each others with their unique assets. All revenues  are shared.',
  },
];

export default function Technology() {
  const currentScreenSize = useGetCurrentScreenSize();

  return (
    <section className="mx-auto my-20 max-w-6.5xl p-5 maxWidth:px-0">
      <h2
        id="technology"
        className={clsx(
          'word-space-widest w-full text-center text-[32px] font-black italic text-primary-blacker-bis xs:text-5xl',
          currentScreenSize > 480
            ? 'yumon-text-shadow-6'
            : 'yumon-text-shadow-4'
        )}
      >
        Technology
      </h2>

      <p className="mx-auto mt-[20px] max-w-4xl text-center text-xl text-white md:text-3xl">
        We make digital ownership invisible and painless for players.
      </p>
      <p className="mx-auto mt-[20px] max-w-4xl text-center text-xl text-white md:text-3xl">
        Developers can now unleash the true value of blockchain for mainstream
        audiences thanks to our SDK.
      </p>

      <div className="mt-[50px] grid grid-cols-1 gap-10 p-10 text-white md:grid-cols-4">
        {FEATURES.map(({ title, description, icon }) => (
          <div key={title} className="flex flex-col items-center">
            {icon}
            <div className="my-2 flex flex-col items-center justify-center md:h-[70px]">
              <h3 className="text-center text-xl leading-5">{title}</h3>
            </div>
            <p className="text-center text-primary-white">{description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}
