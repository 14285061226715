import { useEffect, useState } from 'react';

import Games from './Games';
import {
  IntroductionSectionDesktop,
  IntroductionSectionMobile,
} from './introductory-section';
import JoinCommunitySection from './JoinCommunitySection';
import PartnersSection from './PartnersSection';
import TeamSection from './TeamSection';
import TeaserSection from './TeaserSection';
import Technology from './Technology';

const backgroundImage =
  'https://static.yumon.world/studio/textures/landingpage/background_layer.png';

export default function Homepage() {
  const [isMobileSize, setIsMobileSize] = useState(false);
  const [deviceSize, changeDeviceSize] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleResize = () => changeDeviceSize(window.innerWidth);
    window.addEventListener('resize', handleResize);
    if (deviceSize > 1024) {
      setIsMobileSize(false);
    } else {
      setIsMobileSize(true);
    }
    return () => window.removeEventListener('resize', handleResize);
  }, [deviceSize]);

  return (
    <main className="visible max-w-screen overflow-hidden font-montserrat">
      {isMobileSize ? (
        <IntroductionSectionMobile backgroundImage={backgroundImage} />
      ) : (
        <IntroductionSectionDesktop backgroundImage={backgroundImage} />
      )}
      <Games />
      <Technology />
      <TeaserSection />
      <TeamSection />
      <PartnersSection />
      <JoinCommunitySection />
    </main>
  );
}
