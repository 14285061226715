import './index.css';

import React from 'react';

import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';

import App from './App';
import reportWebVitals from './reportWebVitals';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_ID as string,
};

TagManager.initialize(tagManagerArgs);

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
