import { useEffect, useState } from 'react';

export function useGetCurrentScreenSize() {
  const [currentSizeScreen, setCurrentSizeScreen] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setCurrentSizeScreen(window.innerWidth);

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return currentSizeScreen;
}
