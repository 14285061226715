import { clsx } from 'clsx';

import { SocialLink } from '../../components/call-to-actions';
import { useGetCurrentScreenSize } from '../../hooks/useGetScreenSize';

const ourTeam = {
  advisors: [
    {
      name: 'Rémi Rousseau',
      role: 'Tech entrepreneur & Investor',
      avatar: 'https://static.yumon.world/studio/team/remi-rousseau.png',
      socialNetworkLink: 'https://www.linkedin.com/in/remirousseau/',
    },
    {
      name: 'Nicolas Pouard',
      role: 'VP Blockchain',
      company: 'Ubisoft',
      avatar: 'https://static.yumon.world/studio/team/nicolas-pouard.png',
      socialNetworkLink: 'https://www.linkedin.com/in/nicolas-pouard-17ab9974/',
    },
    {
      name: 'Sébastien Borget',
      role: 'COO & Co-founder',
      company: 'The Sandbox',
      avatar: 'https://static.yumon.world/studio/team/sebastien-borget.png',
      socialNetworkLink: 'https://www.linkedin.com/in/borgetsebastien',
    },
  ],
  founders: [
    {
      name: 'Quentin Le Drean',
      role: 'Creative Director',
      avatar: 'https://static.yumon.world/studio/team/quentin-le-drean.png',
      socialNetworkLink: 'https://www.instagram.com/quentin_cestmoi/?hl=fr',
    },
    {
      name: 'Mathias Salanon',
      role: 'CEO',
      avatar: 'https://static.yumon.world/studio/team/mathias-salanon.png',
      socialNetworkLink: 'https://www.linkedin.com/in/salanon/',
    },
    {
      name: 'Edouard Ruiz',
      role: 'CTO',
      avatar: 'https://static.yumon.world/studio/team/edouard-ruiz.png',
      socialNetworkLink: 'https://www.linkedin.com/in/edouardruiz',
    },
  ],
};

export default function TeamSection() {
  const currentScreenSize = useGetCurrentScreenSize();

  return (
    <>
      <section className="mx-auto mb-32 flex max-h-min max-w-6.5xl flex-col items-center justify-start gap-14 px-6 xs:my-20 xs:gap-20 maxWidth:px-0">
        <div className="flex w-full flex-col items-center gap-4 p-10 xs:gap-10">
          <h2
            id="team"
            className="mx-auto mt-20 w-full text-center text-5xl xs:block"
          >
            <span
              className={clsx(
                'block font-black italic text-primary-blacker-bis',
                currentScreenSize > 480
                  ? 'yumon-text-shadow-6'
                  : 'yumon-text-shadow-4'
              )}
            >
              Team
            </span>
          </h2>
          <h3 className="text-[22px] font-bold text-white xs:text-[32px]">
            Founders
          </h3>
        </div>
        <div className="flex flex-wrap justify-center gap-14 1.5md:gap-10">
          {ourTeam.founders.map((founder) => (
            <div
              key={founder.name}
              className="flex h-[306px] w-[232px] flex-col items-start justify-end rounded-[20px] border-2 border-primary-darker/40 bg-primary-blacker-bis/40 p-4"
            >
              <div className="relative w-[350px]">
                <img
                  src={founder.avatar}
                  alt={founder.name}
                  width={350}
                  height={327}
                  className="absolute -bottom-10 -left-20 object-contain object-center"
                />
              </div>
              <div className="w-full space-y-1.5">
                <h4 className="text-[22px] text-white">{founder.name}</h4>
                <div className="flex items-center justify-between gap-[6px]">
                  <p className="text-sm text-neutral-light">{founder.role}</p>
                  <a
                    href={founder.socialNetworkLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <SocialLink
                      socialNetwork={
                        founder.name === 'Quentin Le Drean'
                          ? 'instagram'
                          : 'linkedin'
                      }
                      uncolouredLink
                    />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="flex w-full flex-col items-center gap-4 p-10 xs:gap-10">
          <h3 className="text-[22px] font-bold text-white xs:text-[32px]">
            Advisors
          </h3>
        </div>
        <div className="flex flex-wrap justify-center gap-14 1.5md:gap-10">
          {ourTeam.advisors.map((advisor) => (
            <div
              key={advisor.name}
              className="flex h-[306px] w-[232px] flex-col items-start justify-end rounded-[20px] border-2 border-primary-darker/40 bg-primary-blacker-bis/40 p-4"
            >
              <div className="relative w-[350px]">
                <img
                  src={advisor.avatar}
                  alt={advisor.name}
                  width={350}
                  height={327}
                  className="absolute -bottom-10 -left-20"
                />
              </div>
              <div className="w-full space-y-1.5">
                <h4 className="text-[22px] text-white">{advisor.name}</h4>
                <div className="flex items-center justify-between gap-[6px]">
                  <div>
                    <p className="text-sm text-neutral-light">{advisor.role}</p>
                    {advisor.company && (
                      <p className="text-sm text-neutral-light">
                        {advisor.company}
                      </p>
                    )}
                  </div>
                  <a
                    href={advisor.socialNetworkLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <SocialLink socialNetwork="linkedin" uncolouredLink />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="text-center text-lg text-white">
          <p>+ an outstanding team of 12 amazing engineers, game</p>
          <p>developers, marketers and artists</p>
        </div>
      </section>
    </>
  );
}
