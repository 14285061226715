import { clsx } from 'clsx';
import ReactPlayer from 'react-player';

import { useGetCurrentScreenSize } from '../../hooks/useGetScreenSize';

export default function TeaserSection() {
  const currentScreenSize = useGetCurrentScreenSize();

  return (
    <section className="my-20 mx-auto max-w-6.5xl space-y-10 px-6 maxWidth:px-0">
      <h2
        className={clsx(
          'word-space-widest w-full text-center text-[32px] font-black italic text-primary-blacker-bis xs:text-5xl',
          currentScreenSize > 480
            ? 'yumon-text-shadow-6'
            : 'yumon-text-shadow-4'
        )}
      >
        Discover the Yumon World
      </h2>
      <div className="relative overflow-hidden rounded-[18px] pt-[56%] sm:rounded-[28px] md:rounded-[32px] lg:rounded-[42px]">
        <ReactPlayer
          url="https://www.youtube.com/watch?v=Al0Ek0sY5SI"
          width="100%"
          height="100%"
          className="absolute top-0 left-0"
          config={{
            youtube: {
              playerVars: { showinfo: 1, controls: 2, color: 'white' },
            },
          }}
        />
      </div>
    </section>
  );
}
