import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';

import { MainLayout } from './components/layouts';
import { Homepage, NotFound } from './pages';

function AppContent() {
  return (
    <div className="bg-primary-blacker-bis font-montserrat">
      <MainLayout>
        <div className="flex min-h-screen flex-col justify-center">
          <Outlet />
        </div>
      </MainLayout>
    </div>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AppContent />}>
          <Route path="" element={<Homepage />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
