export default function NotFound() {
  const aspectRatio = 1920 / 1080;
  const actualWidth = 800;
  const actualHeight = Math.round(actualWidth / aspectRatio);

  return (
    <>
      <div className="mx-auto flex max-h-screen max-w-6.5xl flex-col items-center justify-center font-montserrat">
        <img
          src="https://static.yumon.world/studio/logos/yumon-world.png"
          srcSet="https://static.yumon.world/studio/logos/yumon-world.png 2x"
          width={actualWidth}
          height={actualHeight}
          alt="Yumon World Logo"
          className="mb-12"
        />
        <div className="flex flex-col items-center justify-center text-center text-[32px] italic text-white sm:text-[48px] md:text-[64px]">
          <h1 className="font-bold">404.....</h1>
          <h1>Page not found</h1>
        </div>
      </div>
    </>
  );
}
