import * as React from 'react';

import { svgLogoDefinitions, SvgLogo } from './SvgLogo';

const variants = {
  yumonWhiteOutline: 'yumonWhiteOutline',
  yumonWhiteType: 'yumonWhiteType',
  yumonBlackOutline: 'yumonBlackOutline',
  yumonBlackType: 'yumonBlackType',
};

const sizes = {
  xs: 30,
  sm: 45,
  md: 73,
  lg: 97,
  xl: 120,
  '2xl': 180,
  '3xl': 240,
};

type LogoProps = React.SVGAttributes<SVGElement> & {
  variant: keyof typeof variants;
  size: keyof typeof sizes;
};

export function Logo({ variant, size, ...props }: LogoProps) {
  return (
    <SvgLogo
      logo={svgLogoDefinitions[variant]}
      width={sizes[size]}
      {...props}
    />
  );
}
