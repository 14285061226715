import { clsx } from 'clsx';

import { useGetCurrentScreenSize } from '../../hooks/useGetScreenSize';

export default function Games() {
  const currentScreenSize = useGetCurrentScreenSize();

  return (
    <section className="mx-auto my-20 max-w-6.5xl p-5 maxWidth:px-0">
      <h2
        id="games"
        className={clsx(
          'word-space-widest w-full text-center text-[32px] font-black italic text-primary-blacker-bis xs:text-5xl',
          currentScreenSize > 480
            ? 'yumon-text-shadow-6'
            : 'yumon-text-shadow-4'
        )}
      >
        Games
      </h2>
      <p className="mx-auto mt-[20px] max-w-4xl text-center text-xl text-white md:text-3xl">
        We add a competitive multiplayer and a cross-game avatar layers to the
        gameplays players love the most.
      </p>

      <p className="mx-auto mt-[20px] max-w-4xl text-center text-xl text-white md:text-3xl">
        You can now compete, get rewarded and trade among the same ecosystem.
      </p>

      <a href="https://app.yumon.world/" target="_blank" rel="noreferrer">
        <div className="m-10 mt-[70px] flex flex-col overflow-hidden rounded-[40px] bg-primary-blacker md:h-[300px] md:flex-row">
          <div
            className="min-h-[150px] w-[100%] md:w-[50%]"
            style={{
              backgroundImage:
                'url(https://static.yumon.world/studio/banners/league_banner.png)',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
            }}
          />
          <div className="flex w-[100%] items-center p-[20px] md:w-[50%]">
            <h3 className="text-4xl font-bold text-white md:text-6xl">
              Discover our games, start now!
            </h3>
          </div>
        </div>
      </a>
    </section>
  );
}
