import { useState } from 'react';

import { GoogleSpreadsheet } from 'google-spreadsheet';

import { Button } from '../../components/call-to-actions';
import { Input } from '../../components/forms';
import { YUMON_DISCORD_WEB_LINK } from '../../constants';
import { useGetCurrentScreenSize } from '../../hooks/useGetScreenSize';

type FormData = {
  mail: string;
};

const GOOGLE_API_PRIVATE_KEY = process.env
  .REACT_APP_GOOGLE_API_PRIVATE_KEY as string;
const GOOGLE_API_CLIENT_EMAIL = process.env
  .REACT_APP_GOOGLE_API_CLIENT_EMAIL as string;
const GOOGLE_API_SPREADSHEET_ID = process.env
  .REACT_APP_GOOGLE_API_SPREADSHEET_ID as string;
const GOOGLE_API_SHEET_ID = process.env.REACT_APP_GOOGLE_API_SHEET_ID as string;

export default function JoinCommunitySection() {
  const currentScreenSize = useGetCurrentScreenSize();

  const [formData, setFormData] = useState<FormData>({ mail: '' });
  const [successSubscribing, setSuccessSubscribing] = useState(false);

  const doc = new GoogleSpreadsheet(GOOGLE_API_SPREADSHEET_ID);

  const appendSpreadsheet = async (row: FormData) => {
    try {
      await doc.useServiceAccountAuth({
        client_email: GOOGLE_API_CLIENT_EMAIL,
        private_key: GOOGLE_API_PRIVATE_KEY,
      });
      await doc.loadInfo();

      const sheet = doc.sheetsById[GOOGLE_API_SHEET_ID];
      const result = await sheet.addRow(row);
      if (result) {
        setSuccessSubscribing(true);
      }
      return result;
    } catch (error) {
      console.error('error sheet', error);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    appendSpreadsheet(formData);
  };

  return (
    <section className="h-full w-full bg-primary-blacker-bis">
      <div className="mx-auto max-w-6.5xl py-[140px] px-6 maxWidth:px-0">
        <div className="mx-auto flex max-h-min w-full flex-col items-center gap-8 rounded-[40px] bg-neutral-blacker/80 p-14 sm:w-4/5 lg:w-3/5">
          <h2 className="text-22px text-center font-black text-white xs:text-[28px]">
            Join our community
          </h2>
          <div className="flex w-full flex-wrap items-center justify-center gap-4 md:flex-nowrap">
            <a
              href={YUMON_DISCORD_WEB_LINK}
              target="_blank"
              rel="noreferrer"
              className="w-full"
            >
              <Button
                iconChoice="discord"
                size={currentScreenSize > 640 ? 'lgfull' : 'mdfull'}
                startIcon
                variant="quaternary"
              >
                Discord
              </Button>
            </a>
            <a
              href="https://twitter.com/YUMONworld"
              target="_blank"
              rel="noreferrer"
              className="w-full"
            >
              <Button
                iconChoice="twitter"
                size={currentScreenSize > 640 ? 'lgfull' : 'mdfull'}
                startIcon
                variant="quaternary"
              >
                Twitter
              </Button>
            </a>
          </div>
          <div className="flex w-full flex-col items-center gap-8">
            <p className="text-base text-white">and mailing list for updates</p>
            <form onSubmit={handleSubmit} className="w-full">
              <div className="flex w-full flex-col items-start justify-between gap-4 md:flex-row">
                <div className="w-full flex-grow">
                  <Input
                    inputName="mail"
                    placeholder="Your e-mail address"
                    type="email"
                    successMessage={
                      successSubscribing
                        ? 'Thank you for subscribing!'
                        : undefined
                    }
                    fullWidth
                    onChange={handleInputChange}
                  />
                </div>
                <Button
                  type="submit"
                  size={currentScreenSize > 768 ? 'lg' : 'lgfull'}
                  variant="tertiary"
                >
                  Subscribe
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
