import { ClickAwayListener } from '@mui/material';
import { clsx } from 'clsx';
import { Link as RouterLink } from 'react-router-dom';

import { Button } from '../../call-to-actions';
import Icon, { iconDefinitions } from '../../icon';
import { Logo } from '../../logo';

type HeadMobileProps = {
  openMobileMenu: boolean;
  setOpenMobileMenu: (openMobileMenu: boolean) => void;
};

export default function HeaderMobile({
  setOpenMobileMenu,
  openMobileMenu,
}: HeadMobileProps) {
  return (
    <ClickAwayListener
      onClickAway={() => setOpenMobileMenu(false)}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
    >
      <nav
        className="mx-auto block h-full w-full p-6 md:hidden maxWidth:px-0"
        aria-label="Top"
      >
        <div className="flex h-full w-full flex-col justify-start gap-6">
          <div className="flex items-center justify-between gap-6">
            <div className="flex gap-5">
              <button
                title="menu"
                onClick={() => setOpenMobileMenu(!openMobileMenu)}
                className="relative w-[35px] "
              >
                <Icon
                  iconDefinition={iconDefinitions.drawer}
                  width={35}
                  className={clsx(
                    openMobileMenu ? 'opacity-0' : 'opacity-100',
                    'absolute top-0 transition-opacity duration-300 ease-in'
                  )}
                />
                <Icon
                  iconDefinition={iconDefinitions.xMark}
                  width={35}
                  className={clsx(
                    openMobileMenu ? 'opacity-100' : 'opacity-0',
                    'absolute top-0 transition-opacity duration-300 ease-in'
                  )}
                />
              </button>
              <RouterLink to="/" title="Homepage">
                <Logo size="lg" variant="yumonWhiteOutline" />
              </RouterLink>
            </div>
            <div className="flex items-center gap-4">
              <a
                href="https://app.yumon.world/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button variant="primary" size="sm" className="font-bold">
                  Get in
                </Button>
              </a>
            </div>
          </div>
          <div
            className={clsx(
              'mx-auto flex h-full w-full flex-col items-center justify-center divide-y-2 divide-neutral-black text-sm text-white',
              openMobileMenu ? 'block' : setTimeout(() => 'hidden', 300)
            )}
          >
            <a
              href="/#games"
              className="flex h-10 w-full items-center justify-center"
            >
              Games
            </a>
            <a
              href="/#technology"
              className="flex h-10 w-full items-center justify-center"
            >
              Technology
            </a>
            <a
              href="/#team"
              className="flex h-10 w-full items-center justify-center"
            >
              Team
            </a>
          </div>
        </div>
      </nav>
    </ClickAwayListener>
  );
}
