import {
  YUMON_DISCORD_WEB_LINK,
  YUMON_GAMEBOOK_LINK,
  YUMON_GAMES_LINK,
  YUMON_JOBS_LINK,
  YUMON_PRIVACY_POLICY_LINK,
  YUMON_TERMS_AND_CONDITIONS_LINK,
} from '../../../constants';
import { SocialLink } from '../../call-to-actions';
import { Logo } from '../../logo';

export default function Footer() {
  return (
    <footer className="z-20 h-fit w-full border-t-2 border-neutral-black md:h-[290px]">
      <div className="mx-auto h-full w-full max-w-6.5xl py-20 px-6 maxWidth:px-0">
        <div className="flex h-full w-full flex-col items-start justify-start gap-10 sm:grid sm:grid-cols-4 sm:gap-20">
          <div className="flex flex-col items-start gap-4 text-sm text-neutral-light">
            <Logo size="md" variant="yumonWhiteOutline" />
            <p>
              Yumon is a global platform that brings people together through
              play. Enter our playground today!
            </p>
          </div>
          <div className="flex items-start gap-3 text-sm text-white sm:flex-col">
            <a href={YUMON_JOBS_LINK} target="_blank" rel="noreferrer">
              <p>Jobs</p>
            </a>
            <a href="mailto:hello@yumon.app">
              <p>Contact</p>
            </a>
            <a href={YUMON_GAMEBOOK_LINK} target="_blank" rel="noreferrer">
              <p>FAQ</p>
            </a>
          </div>
          <div className="flex items-start gap-3 text-sm text-white sm:flex-col">
            <a
              href={YUMON_TERMS_AND_CONDITIONS_LINK}
              target="_blank"
              rel="noreferrer"
            >
              <p>Terms & Conditions</p>
            </a>
            <a
              href={YUMON_PRIVACY_POLICY_LINK}
              target="_blank"
              rel="noreferrer"
            >
              <p>Privacy Policy</p>
            </a>
            <div className="relative">
              <a href={YUMON_GAMES_LINK} target="_blank" rel="noreferrer">
                <p>👽 💎 ⚡️</p>
              </a>
            </div>
          </div>
          <div className="flex items-start justify-start gap-[6px] fill-white sm:justify-end">
            <a href={YUMON_DISCORD_WEB_LINK} target="_blank" rel="noreferrer">
              <SocialLink socialNetwork="discord" uncolouredLink whiteIcon />
            </a>
            <a
              href="https://twitter.com/YUMONworld"
              target="_blank"
              rel="noreferrer"
            >
              <SocialLink socialNetwork="twitter" uncolouredLink whiteIcon />
            </a>
            <a
              href="https://www.youtube.com/yumon"
              target="_blank"
              rel="noreferrer"
            >
              <SocialLink socialNetwork="youtube" uncolouredLink whiteIcon />
            </a>
            <a
              href="https://www.instagram.com/yumonworld/"
              target="_blank"
              rel="noreferrer"
            >
              <SocialLink socialNetwork="instagram" uncolouredLink whiteIcon />
            </a>
            <a
              href="https://www.linkedin.com/company/yumon/"
              target="_blank"
              rel="noreferrer"
            >
              <SocialLink socialNetwork="linkedin" uncolouredLink whiteIcon />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
