import { useEffect, useState } from 'react';

import { clsx } from 'clsx';

import HeaderDesktop from './HeaderDesktop';
import HeaderMobile from './HeaderMobile';

export default function Header() {
  const [backgroundNavChange, setBackgroundNavChange] = useState(false);
  const [deviceSize, changeDeviceSize] = useState(window.innerWidth);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);

  useEffect(() => {
    const changeNavbarBackground = () => {
      if (window.scrollY >= 80) {
        setBackgroundNavChange(true);
      } else {
        setBackgroundNavChange(false);
      }
    };
    window.addEventListener('scroll', changeNavbarBackground);
    return () => window.removeEventListener('scroll', changeNavbarBackground);
  }, []);

  useEffect(() => {
    if (deviceSize > 768) {
      setOpenMobileMenu(false);
    }
    const handleResize = () => changeDeviceSize(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [deviceSize]);

  return (
    <header
      className={clsx(
        'fixed z-20 w-full overflow-hidden border-b-2 transition-all duration-500 ease-in md:h-20 md:overflow-visible md:transition-colors md:duration-300 md:ease-in',
        openMobileMenu ? 'max-h-[300px]' : 'max-h-20',
        backgroundNavChange || openMobileMenu
          ? 'border-neutral-black bg-primary-blacker-bis'
          : 'border-transparent bg-transparent'
      )}
    >
      <HeaderDesktop />
      <HeaderMobile
        openMobileMenu={openMobileMenu}
        setOpenMobileMenu={setOpenMobileMenu}
      />
    </header>
  );
}
