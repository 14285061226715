import { clsx } from 'clsx';

import { Button } from '../../../components/call-to-actions';
import { useGetCurrentScreenSize } from '../../../hooks/useGetScreenSize';

type IntroductionSectionMobileProps = {
  backgroundImage: string;
};

export default function IntroductionSectionMobile({
  backgroundImage,
}: IntroductionSectionMobileProps) {
  const currentScreenSize = useGetCurrentScreenSize();

  return (
    <>
      <section
        className="h-fit bg-cover bg-right bg-no-repeat 1.5md:h-fit"
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(6, 0, 22, 0) 45%, rgba(6, 0, 22, 1) 100%), linear-gradient(to top, rgba(6, 0, 22, 0) 60%, rgba(6, 0, 22, 1) 100%), url(${backgroundImage})`,
        }}
      >
        <div className="mx-auto flex h-fit max-w-6.5xl flex-col justify-between px-6 maxWidth:px-0">
          <div className="max-h-fit min-h-[750px]">
            <div className="grid h-1/2 grid-cols-1">
              <div className="relative mx-auto mt-28 flex flex-col justify-center gap-8 xs:mt-52">
                <h1 className="z-10 mx-auto w-full font-black text-white">
                  <span
                    className={clsx(
                      'word-space-widest block text-[32px] italic text-primary-blacker-bis xs:text-[64px]',
                      currentScreenSize > 480
                        ? 'yumon-text-shadow-8'
                        : 'yumon-text-shadow-4'
                    )}
                  >
                    Play & Own Games
                  </span>
                  <span className="block w-full text-[28px] leading-[1em] xs:text-[52px] sm:w-3/4">
                    Elevate mass entertainment playgrounds
                  </span>
                </h1>
                <div className="z-10 space-y-1.5">
                  <p className="w-full text-base text-white xs:text-[22px] sm:w-3/4">
                    Yumon enables developers, players and creators to shape the
                    future of play with cutting edge technologies and cross-game
                    digital toys.
                  </p>
                </div>
                <a
                  href="https://app.yumon.world/"
                  target="_blank"
                  rel="noreferrer"
                  className="z-10"
                >
                  <Button
                    startIcon
                    iconChoice="doubleChevronRight"
                    variant="tertiary"
                    size="lg"
                    className="text-lg font-bold"
                  >
                    Play Now
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
