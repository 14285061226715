import * as React from 'react';

import { clsx } from 'clsx';

type IconProps =
  | { startIcon: boolean; endIcon?: never }
  | { endIcon: boolean; startIcon?: never }
  | { endIcon?: undefined; startIcon?: undefined }
  | { startIcon: boolean; endIcon: boolean };

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  fullWidth?: boolean;
  type?: 'text' | 'password' | 'email' | 'number' | 'tel' | 'url';
  disabled?: boolean;
  errorMessage?: string;
  successMessage?: string;
  placeholder: string;
  inputName?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
} & IconProps;

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      type = 'email',
      className = '',
      fullWidth = false,
      disabled = false,
      placeholder = '',
      errorMessage,
      successMessage,
      inputName,
      onChange,
    },
    ref
  ) => {
    return (
      <div className="max-h-min space-y-2.5">
        <input
          ref={ref}
          type={type}
          name={inputName}
          className={clsx(
            'h-14 rounded-[14px] border border-neutral-medium bg-transparent px-4 py-[18px] text-base text-white placeholder:text-neutral-light invalid:border-error-pure hover:bg-neutral-blacker focus:border-primary-medium focus:outline-none focus:ring-1 focus:ring-primary-medium focus:invalid:border-error-pure focus:invalid:ring-error-pure disabled:border-neutral-dark disabled:bg-neutral-black placeholder:disabled:text-neutral-dark',
            errorMessage &&
              'border-error-pure focus:border-error-pure focus:ring-error-pure',
            successMessage &&
              'border-success-pure focus:border-success-pure focus:ring-success-pure',
            fullWidth && 'w-full',
            className
          )}
          disabled={disabled}
          placeholder={placeholder}
          onChange={onChange}
        />
        {errorMessage && (
          <span className="text-sm text-error-pure">{errorMessage}</span>
        )}
        {successMessage && (
          <span className="text-sm text-success-pure">{successMessage}</span>
        )}
      </div>
    );
  }
);

Input.displayName = 'Input';

export default Input;
